"use client"

import {type ReactNode} from "react"
import {type UserStores, UserStoresContext} from "./UserStoresContext"

type Props = {
  children: ReactNode
  serverUserStores: UserStores
}

const UserStoresClientProvider = ({children, serverUserStores}: Props) => {
  return <UserStoresContext.Provider value={serverUserStores}>{children}</UserStoresContext.Provider>
}

export default UserStoresClientProvider
