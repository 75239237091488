"use client"

import {type ReactNode, useEffect, useRef, useState} from "react"

const Openable = ({
  children,
  className,
  open: openChildren,
  closed: closedChildren,
  buttonChildren,
}: {
  children: ReactNode
  open?: ReactNode
  closed?: ReactNode
  buttonChildren?: ReactNode
  className?: string
}) => {
  const [open, setOpen] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!open) return

    const handleOutSideClick = (ev: MouseEvent) => {
      if (!ref.current?.contains(ev.target as Node)) {
        setOpen(false)
      }
    }

    document.addEventListener("click", handleOutSideClick)

    return () => document.removeEventListener("click", handleOutSideClick)
  }, [setOpen, open])

  return (
    <div ref={ref} className="relative">
      <button className={className} onClick={() => setOpen((open) => !open)}>
        {buttonChildren}
        {open ? openChildren : closedChildren}
      </button>
      {open && children}
    </div>
  )
}

export default Openable
