"use client"
import {Input, type InputProps} from "@headlessui/react"
import {cva} from "cva"
import {forwardRef} from "react"
import {twMerge} from "tailwind-merge"

export const input = cva({
  base: "min-w-64 read-only:bg-gray-100 read-only:cursor-default rounded-lg border border-gray-300 bg-white px-4 py-2.5 text-sm font-normal text-gray-900 shadow-sm outline-none ring-brand-400/10 placeholder:text-gray-500 data-[focus]:border-brand data-[focus]:ring",
})

const StyledInput = forwardRef<HTMLInputElement, InputProps>(({className, ...props}, ref) => {
  return (
    <Input
      {...props}
      className={(b) => twMerge(input(), typeof className === "string" ? className : className?.(b))}
      ref={ref}
    />
  )
})
export default StyledInput

StyledInput.displayName = "StyledInput"
