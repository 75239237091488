"use client"
import {compact} from "lodash"
import Link from "next/link"
import {usePathname} from "next/navigation"
import {Fragment, type HTMLAttributeAnchorTarget, type ReactNode, useMemo} from "react"
import {twJoin, twMerge} from "tailwind-merge"

export type MenuItemProps = {
  icon?: ReactNode
  subItems?: Array<SubItemProps | false>
  exact?: boolean
} & SubItemProps

type SubItemProps = {
  title: string
  hrefEq?: string
  href: string
  exact?: boolean
  target?: HTMLAttributeAnchorTarget
}

const Spacer = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
    <rect x="15" width="1" height="36" fill="#E4E4E7" />
  </svg>
)

const matches = (pathname: string, href: string, hrefEq?: string) => pathname.startsWith(hrefEq ?? href)

export const MenuItem = ({href, title, subItems: unfilteredSubItems, icon, hrefEq, exact, target}: MenuItemProps) => {
  const pathname = usePathname()

  const subItems = unfilteredSubItems ? compact(unfilteredSubItems) : undefined

  const selected = exact ? pathname === href : matches(pathname, href, hrefEq)

  const subItemSelected =
    selected ||
    (subItems?.some(({href, hrefEq, exact}) => (exact ? pathname === href : matches(pathname, href, hrefEq))) ?? false)

  return (
    <>
      <Link
        title={title}
        target={target}
        href={href}
        className={"flex items-center gap-4 rounded-md p-2 hover:bg-gray-100"}
      >
        {icon && (
          <span
            className={twJoin(
              "stroke-[1.5]",
              subItemSelected ? "fill-brand stroke-brand" : "fill-gray-700 stroke-gray-700",
            )}
          >
            {icon}
          </span>
        )}
        <span
          className={twMerge(
            "text-sm font-medium text-gray-500",
            icon !== undefined && "text-gray-700",
            subItemSelected && "text-brand",
          )}
        >
          {title}
        </span>
      </Link>
      {subItems && subItems.length > 0 && (
        <div className={twJoin("flex flex-col", !subItemSelected && "hidden")}>
          {subItems.map((item) => (
            <Fragment key={item.href}>
              <div className="flex">
                <Spacer />
                <span className="grow">
                  <MenuItem {...item} />
                </span>
              </div>
              <div className="last:hidden"></div>
            </Fragment>
          ))}
        </div>
      )}
    </>
  )
}

export default MenuItem
