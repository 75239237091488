import {type FirebaseOptions} from "firebase/app"

const getConfig = () => {
  const projectId = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID
  const apiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY
  const authDomain = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
  const storageBucket = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
  const typesenseHost = process.env.NEXT_PUBLIC_TYPESENSE_HOST

  if (!projectId) throw new Error("Missing FIREBASE_PROJECT_ID env variable")
  if (!apiKey) throw new Error("Missing FIREBASE_API_KEY env variable")
  if (!authDomain) throw new Error("Missing FIREBASE_AUTH_DOMAIN env variable")
  if (!storageBucket) throw new Error("Missing FIREBASE_STORAGE_BUCKET env variable")
  if (!typesenseHost) throw new Error("Missing TYPESENSE_HOST env variable")

  return {
    projectId,
    apiKey,
    authDomain,
    storageBucket,
    typesenseHost,
  }
}

export const {projectId, apiKey, authDomain, storageBucket, typesenseHost} = getConfig()

export const firebaseConfig: FirebaseOptions = {
  projectId,
  apiKey,
  authDomain,
  storageBucket,
}

export const authEmulatorHost = process.env.NEXT_PUBLIC_AUTH_EMULATOR_HOST
export const firestoreEmulatorHost = process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST
export const storageEmulatorHost = process.env.NEXT_PUBLIC_STORAGE_EMULATOR_HOST
export const functionsEmulatorHost = process.env.NEXT_PUBLIC_FUNCTIONS_EMULATOR_HOST
